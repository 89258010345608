<template>
  <div class="content">
    <div class="content__title">
      <h1 class="title title--big title--theme">Импорт членов РСМ 1</h1>
    </div>
    <div class="content__title content__title--party content__title--start">
      <UploaderExcel
        @fileUpload="fetchLogs"
        :extensions="['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
        btnTitle="Загрузить файл Excel (.xlsx)"
        url="members/journal/import/"
      />
      <button
        @click="onDownloadFile('journal/GET_DOWNLOAD', '', 'Пример файла импорта', false)"
        type="button"
        class="link link--fz"
      >
        <img src="@/assets/img/example-import.svg" alt="">
        <span>Скачать пример файла</span>
      </button>
    </div>
    <div class="content__title content__title--party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
                    :options="limitOptions"
                    :selected="limitSelected"
                    :closeOnOutsideClick="true"
                    @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default"
    >
      <div slot="file_url" slot-scope="props">
        <button class="table-default__button" @click="onDownloadFile(props.row.file_url, '', props.row.name, true)">Скачать</button>
      </div>
      <div slot="error_count" slot-scope="props">
        <button v-if="props.row.error_count > 0" class="table-default__button" @click="onDownloadFile('journal/GET_REPORT', props.row.id, props.row.name, false)">
          {{ `${props.row.error_count} (Скачать отчёт)` }}
        </button>
        <span v-else>{{ props.row.error_count }}</span>
      </div>
      <div slot="updated_at" slot-scope="props">
        <span v-if="!props.row.in_process">{{ props.row.updated_at }}</span>
        <div v-else class="loading loading--mini" >
          <div class="loading__body">
            <div class="effect-1 loading__effects"></div>
            <div class="effect-2 loading__effects"></div>
            <div class="effect-3 loading__effects"></div>
          </div>
        </div>
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns'
import UploaderExcel from '@/components/UploaderExcel'

export default {
  name: 'JournalList',
  components: {
    dropdown,
    UploaderExcel
  },
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'region_name', 'file_url', 'success_count', 'error_count', 'created_at', 'updated_at'],
        options: {
          headings: {
            id: 'ID',
            region_name: 'Регион',
            file_url: 'Файл импорта',
            success_count: 'Успешно импортировано',
            error_count: 'Ошибок импорта',
            created_at: 'Начат',
            updated_at: 'Окончен',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: {name: 20},
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchItems(1, this.limitSelected.name)
  },
  methods: {
    fetchLogs() {
      this.fetchItems(1, this.limitSelected.name)
    },
    fetchItems(page = 1, limit) {
      this.$store.dispatch('journal/GET_LOG', { page, limit })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    updateLimiter(data) {
      this.limitSelected.name = data.name
      this.$refs.userListTable.setLimit(data.name)
      this.fetchItems(this.pagination.page, this.limitSelected.name)
    },
    clickPaginationCallback(page) {
      this.fetchItems(page, this.limitSelected.name)
    },
    returnStart() {
      return (this.pagination.page * this.table.items.length) - (this.table.items.length - 1)
    },
    returnEnd() {
      return this.returnStart() + this.table.items.length - 1
    },
    onDownloadFile(url, id, name, simpleDownload) {
      const link = document.createElement('a')
      link.style = 'display: none'
      if (simpleDownload) {
        link.href = url
        link.download = name
        link.click()
      } else {
        this.$store.dispatch(url, id)
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            link.href = window.URL.createObjectURL(blob)
            link.download = name
            link.click()
            window.URL.revokeObjectURL(link.href)
          })
          .catch(error => {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: `Ошибка: ${ error.message }`
            })
          })
      }
    },
  }
};
</script>
